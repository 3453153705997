import { EventsWaterpolo } from "../../events/index"

export default {
	layout: "court",
	events: EventsWaterpolo,
	field: "court-vaterpolo",
	numOfHalftimes: 4,

	penalties: true,
	playerNumbers: true,
	hasBench: true,
	hasCaptain: true,
	hasMvp: true,
	hasSockets: true,
	hasPauses: true,
	teamPausesAllowed: 3,

	formations: {
		default: [
			{ name: "GK", coordinate: { x: 1, y: 11 } },
			{ name: "1", coordinate: { x: 3, y: 19 } },
			{ name: "2", coordinate: { x: 7, y: 16 } },
			{ name: "3", coordinate: { x: 11, y: 11 } },
			{ name: "4", coordinate: { x: 7, y: 6 } },
			{ name: "5", coordinate: { x: 3, y: 3 } },
			{ name: "6", coordinate: { x: 4, y: 11 } },
		],
	},

	teamComments: ["${name} je danas u potpunosti dominantnija ekipa u bazenu."],
}

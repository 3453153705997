export default {
	quick: [
		{
			name: "Gol",
			followup: {
				type: "teamPlayers",
				allowIgnore: true,
				comment: "${name} (${teamName}) mu je asistirao.",
			},
			increment: 1,
			key: "Goal",
			types: ["A", "B", "C"],
			comment: "Pogodak za (${teamName}). Zabio je sada ${name}.",
		},
		{
			name: "U okvir",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali siguran je na golu bio ${name} (${teamName}).",
			},
			comment:
				"Bio je ovo dobar pokušaj za (${teamName}). Udarac je uputio ${name},",
			key: "Shot on target",
			types: ["A"],
		},
		{
			name: "Van gola",
			comment:
				"Pokušao je sada ${name} (${teamName}), ali udarac je bio neprecizan i završio je pored gola.",
			key: "Shot off target",
			types: ["A"],
		},
		{
			name: "Blok",
			comment:
				"Još jedan pokušaj za (${teamName}), ali udarac koji je uputio ${name} je završio u bloku.",
			key: "Blocked shot",
			types: ["A"],
		},
	],
	normal: [
		{
			name: "Autogol",
			followup: {
				type: "enemyPlayers",
				allowIgnore: true,
				comment:
					"Na to ga je potaknuo ${name} (${teamName}) iz protivničke ekipe.",
			},
			increment: -1,
			comment:
				"AUTOGOL! Nespretnom reakcijom ${name} (${teamName}) posprema loptu u vlastitu mrežu.",
			key: "Own goal",
			types: ["A", "B"],
		},
		{
			name: "Žuti karton",
			comment: "Žuti karton zaradio je sada ${name} (${teamName}).",
			key: "Yellow card",
			types: ["A", "B"],
		},
		{
			name: "Drugi žuti",
			comment:
				"CRVENI KARTON! ${name} (${teamName}) je završio svoju utakmicu.",
			key: "Red card",
			types: ["A", "B"],
		},
		{
			name: "Plavi karton",
			comment:
				"PLAVI KARTON! Teška situacija za (${teamName}), nakon ove odluke koju je zaradio ${name}.",
			key: "Blue card",
			types: ["A", "B"],
		},
		{
			name: "2 minute kazna",
			if: { has: 3, name: "3x2min" },
			comment:
				"Sudac se oglasio i zaustavio utakmicu. ${name} (${teamName}) je zaradio isključenje od dvije minute.",
			key: "Suspension",
			types: ["A", "B"],
		},
		{
			name: "Stativa",
			comment:
				"Odličan pokušaj, ali nije ${name} (${teamName}) imao sreće. Udarac je završio na stativi.",
			key: "Post",
			types: ["A"],
		},
		{
			name: "7 gol",
			increment: 1,
			key: "7m goal",
			types: ["A", "B"],
			comment:
				"Precizan sa sedam metara bio je ${name} (${teamName}). Neobranjivo za protivničkog vratara.",
			isPenalty: true,
			coordinate: { x: 8, y: 11 },
		},
		{
			name: "7 obrana",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali ${name} (${teamName}) je to obranio.",
			},
			key: "7m on target",
			types: ["A"],
			comment: "Obrana! Sa sedam metara ${name} (${teamName}) je pucao,",
			isPenalty: true,
			coordinate: { x: 8, y: 11 },
		},
		{
			name: "7 stativa",
			key: "7m post",
			types: ["A"],
			comment:
				"Nema pogotka, stativa. Sa sedam metara neprecizan je bio ${name}. Nedostajalo je ovdje malo sreće za (${teamName}).",
			isPenalty: true,
			coordinate: { x: 8, y: 11 },
		},
		{
			name: "7 van gola",
			key: "7m off target",
			types: ["A"],
			comment:
				"Kakav promašaj! Sa sedam metara neprecizan je bio ${name} (${teamName}). Udarac je završio pored gola.",
			isPenalty: true,
			coordinate: { x: 8, y: 11 },
		},
	],
}

// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import auth from "./auth"
import players from "./allReducers/players"
import rerender from "./allReducers/rerender"
import events from "./allReducers/events"
import scoreCounter from "./allReducers/scoreCounter"
import time from "./allReducers/time"
import penaltiesOrEnd from "./allReducers/penaltiesOrEnd"
import isDisconnected from "./allReducers/isDisconnected"

const rootReducer = combineReducers({
	auth,
	rerender,
	players,
	events,
	scoreCounter,
	time,
	penaltiesOrEnd,
	isDisconnected,
})

export default rootReducer

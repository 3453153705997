import { GET, POST } from "./functions"

// * Paths
export const BASE_URL = process.env.REACT_APP_MAIN_URL

export const getSports = () => GET(`${BASE_URL}delegate/sports-of-matches`)
export const getGamesBySport = (sport) => GET(`${BASE_URL}delegate/games/${sport}`)
export const getGameByID = (id, body) => GET(`${BASE_URL}delegate/game/${id}`, body)
export const getStartedGameByID = (sport, gameId) =>
	GET(`${BASE_URL}delegate/get-started-game?sport=${sport}&gameId=${gameId}`)

export const postAuth = (body) => POST(`${BASE_URL}login`, body)
export const postResult = (body) => POST(`${BASE_URL}delegate/set-result`, body)
export const postMVP = (body) => POST(`${BASE_URL}delegate/set-mvp`, body)
export const postSetFormation = (body) =>
	POST(`${BASE_URL}delegate/setFormation`, body)
export const postStartGame = (id, body, queryParams) =>
	POST(`${BASE_URL}delegate/game/${id}/start`, body, queryParams)
export const postEndGame = (body) => POST(`${BASE_URL}delegate/game/end2`, body)
export const getOldGamesBySport = (body) =>
	GET(`${BASE_URL}mobile/old-matches`, body)
// ? It's weird that this get request is actually a post, but backend made it like this
export const postGetEvents = (body) => POST(`${BASE_URL}delegate/get-events`, body)
export const postForfeit = (body) => POST(`${BASE_URL}delegate/forfeit`, body)

import { toast } from "react-toastify"

function handleAPIError(currentPath, body) {
	// if (currentPath != location.href) return {}
	if (body?.errorMessage)
		toast.error(`Vaš zahtjev nije prošao, razlog: ${body.errorMessage}`)
	else if (navigator.onLine)
		toast.error("Vaš zahtjev nije prošao, molimo probajte opet kasnije.")
	return { error: true }
}

function handleNoInternet() {
	if (navigator.onLine) return false
	toast.warning("Niste spojeni na internet.")
	return true
}

function getUrlParams(params) {
	let toReturn = ""
	if (params) {
		toReturn = "?"
		Object.entries(params).forEach(
			([key, value]) => (toReturn += `${key}=${value}&`)
		)
		toReturn = toReturn.slice(0, -1)
	}
	return toReturn
}

export const GET = async (url, body) => {
	url += getUrlParams(body)

	const currentPath = location.href

	try {
		const response = await fetch(url, {
			method: "GET",
			headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
		})
		if ((response.status >= 200 && response.status < 300) || response.ok)
			return await response.json()

		if (response.status >= 300)
			return handleAPIError(currentPath, await response.json())
	} catch (e) {
		// eslint-disable-next-line no-console
		if (handleNoInternet()) return { offline: true }
		console.log("Error when fetching with GET", e)
	}

	// Checking
	return handleAPIError(currentPath)
}

export const POST = async (url, body, queryParams) => {
	url += getUrlParams(queryParams)

	const currentPath = location.href
	let response

	try {
		response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
				"Content-Type": "application/json",
			},
		})

		if ((response.status >= 200 && response.status < 300) || response.ok)
			return await response.json()

		if (response.status >= 300)
			return handleAPIError(currentPath, await response.json())
	} catch (e) {
		// eslint-disable-next-line no-console
		if (handleNoInternet()) return { offline: true }
		console.log("Error when fetching with POST", e)
	}

	return handleAPIError(currentPath)
}

import { EventsFutsal } from "../../events/index"

export default {
	layout: "court",
	events: EventsFutsal,
	field: "court-futsal",
	numOfHalftimes: 2,
	overtimeMinutes: 5,

	penalties: true,
	playerNumbers: true,
	hasBench: true,
	hasCaptain: true,
	hasMvp: true,
	hasSockets: true,
	hasPauses: true,
	teamPausesAllowed: 1,
	twoJerseyColors: true,

	formations: {
		"1-2-1": [
			{ name: "GK", coordinate: { x: 1, y: 11 } },
			{ name: "LB", coordinate: { x: 10, y: 4 } },
			{ name: "CB", coordinate: { x: 8, y: 11 } },
			{ name: "RB", coordinate: { x: 10, y: 18 } },
			{ name: "ST", coordinate: { x: 13, y: 11 } },
		],

		"1-1-1-1": [
			{ name: "GK", coordinate: { x: 1, y: 11 } },
			{ name: "CB1", coordinate: { x: 8, y: 6 } },
			{ name: "CB2", coordinate: { x: 8, y: 16 } },
			{ name: "PIV", coordinate: { x: 13, y: 11 } },
			{ name: "ST", coordinate: { x: 17, y: 11 } },
		],
	},

	teamComments: [
		"Minuta odmora za ${name}. Sada je na izborniku da pripremi svoju ekipu najbolje što može za nastavak.",
		"${name} je sada u lošoj poziciji. Svaki sljedeći prekršaj znači deseterac za protivnika.",
		"${name} uvodi golmana igrača. Pokušat će sada s igračem više napraviti prevagu na terenu. Slijedi nam napad 5 na 4, bit će zanimljivo vidjeti hoće li uspjeti realizirati igrača više.",
		"${name} u potpunosti dominira ovom utakmicom. Odlična utakmica za njih.",
		"Mirna utakmica za sada, uz malu inicijativu koja pokazuje ${name}.",
	],
}

/* eslint-disable prettier/prettier */
// ? Action Strings
// * All
export const ALL_INIT = "ALL_INIT"
export const ALL_RESET = "ALL_RESET"
// * Players
export const PLAYERS_INIT = "SET_PLAYERS"
export const PLAYERS_PIN = "PIN_PLAYERS"
export const PLAYERS_SWITCH_PIN = "SWITCH_PIN_PLAYERS"
// * Events
export const EVENTS_ADD = "ADD_EVENT"
export const EVENTS_REMOVE = "REMOVE_EVENTS"
export const EVENTS_EDIT = "EDIT_EVENTS"
export const EVENTS_INSERT_SORT = "INSERT_AND_SORT_EVENTS"
// * ScoreCounter
export const SC_UPDATE = "SC_UPDATE"
export const SC_SET = "SC_SET"
export const SC_RESET = "SC_RESET"
// * IsPaused
export const TIME_TOGGLE = "PAUSED_TIME"
export const TIME_HALFTIME = "HALFTIME_TIME"
export const TIME_OVERTIME = "OVERTIME_TIME"
export const TIME_FROM_SOCKET = "TIME_FROM_SOCKET"
// * PenaltiesOrEnd
export const PENALTIES_OR_END_SET = "SET_PENALTIES_OR_END"
// * IsDisconnected
export const SET_IS_DISCONNECTED = "SET_IS_DISCONNECTED"
// * GlobalSets
export const SET_GLOBAL_SETS = "SET_GLOBAL_SETS"
// * ReRender
export const FORCE_RERENDER = "FORCE_RERENDER"
export const FORCE_ISLOADING = "FORCE_ISLOADING"

// ? Action Objects
// * All
export const initReducers = () => ({ type: ALL_INIT })
export const resetAllReducers = () => ({ type: ALL_RESET  })
// * Players
export const pinPlayers = (_id, isAwayTeam) => ({ type: PLAYERS_PIN, _id, isAwayTeam })
export const pinSwitchPlayers = (_id, followup_id, isAwayTeam) => ({ type: PLAYERS_SWITCH_PIN, _id, followup_id, isAwayTeam })
// * Events
export const addEvent = (params, saveLocally = true) => ({ type: EVENTS_ADD, params, saveLocally })
export const removeEvent = (id) => ({ type: EVENTS_REMOVE, id})
export const editEvent = (params) => ({ type: EVENTS_EDIT, params })
export const addArrayAndSort = (array) => ({ type: EVENTS_INSERT_SORT, array })
// * ScoreCounter
export const updateScoreCounter = (team, points) => ({ type: SC_UPDATE, team, points })
export const setScoreCounter = (result) => ({ type: SC_SET, result })
export const resetScoreCounter = () => ({ type: SC_RESET })
// * IsPaused
export const setIsPaused = (isPaused, team) => ({ type: TIME_TOGGLE, isPaused, team })
export const endOfHalftime = () => ({ type: TIME_HALFTIME })
export const toggleOvertime = (isDone) => ({ type: TIME_OVERTIME, isDone })
export const setTimeFromSocket = (time) => ({ type: TIME_FROM_SOCKET, time })
// * PenaltiesOrEnd
export const setPenaltiesOrEnd = (value) => ({ type: PENALTIES_OR_END_SET, value })
// * IsDisconnected
export const setIsDisconnected = (value) => ({ type: SET_IS_DISCONNECTED, value })
// * GlobalSets
export const setGlobalSets = (result) => ({ type: SET_GLOBAL_SETS, result })
// * ReRender
export const forceReRender = () => ({ type: FORCE_RERENDER})
export const forceIsLoading = (value) => ({ type: FORCE_ISLOADING, value})
import { EventsBasketball } from "../../events/index"

export default {
	layout: "court",
	events: EventsBasketball,
	field: "court-basketball",
	overtimeMinutes: 5,
	scorePoints: [1, 2, 3],
	numOfHalftimes: 4,

	playerNumbers: true,
	hasBench: true,
	hasCaptain: true,
	hasMvp: true,
	hasSockets: true,
	hasPauses: true,
	teamPausesAllowed: 6,
	oneJerseyColor: true,


	formations: {
		default: [
			{ name: "1", coordinate: { x: 13, y: 14 } },
			{ name: "2", coordinate: { x: 13, y: 8 } },
			{ name: "3", coordinate: { x: 5, y: 16 } },
			{ name: "4", coordinate: { x: 5, y: 6 } },
			{ name: "5", coordinate: { x: 4, y: 11 } },
		],
	},

	teamComments: [
		"Minuta odmora za ${name}. Sada je na izborniku da pripremi svoju ekipu najbolje što može za nastavak.",
		"${name} ulazi u bonus. Svaki sljedeći prekršaj znači slobodna bacanja za protivnika.",
		"${name} u potpunosti dominira ovom utakmicom. Odlična utakmica za njih.",
	],
}

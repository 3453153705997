import { SET_IS_DISCONNECTED } from "../../actions/actions"
// **  Initial State
const initialState = false

// ** Reducer
export default (state = initialState, action) => {
	switch (action.type) {
		case SET_IS_DISCONNECTED:
			return action.value
		default:
			return state
	}
}

import {
	ALL_RESET,
	ALL_INIT,
	PLAYERS_PIN,
	PLAYERS_SWITCH_PIN,
} from "../../actions/actions"

// **  Initial State
const initialState = {
	homeTeam: {},
	awayTeam: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case ALL_INIT:
			return {
				homeTeam: JSON.parse(localStorage.getItem("homeTeam") ?? "{}"),
				awayTeam: JSON.parse(localStorage.getItem("awayTeam") ?? "{}"),
			}
		case PLAYERS_PIN: {
			const { _id, isAwayTeam } = action
			const teamName = isAwayTeam ? "awayTeam" : "homeTeam"
			const team = { ...state[teamName] }

			const i = team.players.findIndex((player) => player.playerId == _id)

			const tempPlayer = team.players[i]
			team.players[i] = { ...tempPlayer, pin: !tempPlayer.pin }
			localStorage.setItem(teamName, JSON.stringify(team))

			return { ...state, [teamName]: team }
		}
		case PLAYERS_SWITCH_PIN: {
			const { _id, followup_id, isAwayTeam } = action
			const teamName = isAwayTeam ? "awayTeam" : "homeTeam"
			const team = { ...state[teamName] }

			const i = team.players.findIndex((player) => player.playerId == _id)
			const tempPlayer = team.players[i]
			team.players[i] = { ...tempPlayer, pin: false }

			const i2 = team.players.findIndex(
				(player) => player.playerId == followup_id
			)
			const tempPlayer2 = team.players[i2]
			team.players[i2] = { ...tempPlayer2, pin: true }

			localStorage.setItem(teamName, JSON.stringify(team))

			return { ...state, [teamName]: team }
		}
		case ALL_RESET:
			localStorage.removeItem("awayTeam")
			localStorage.removeItem("homeTeam")
			return { homeTeam: {}, awayTeam: {} }
		default:
			return state
	}
}

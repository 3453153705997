import {
	ALL_RESET,
	TIME_FROM_SOCKET,
	TIME_HALFTIME,
	TIME_OVERTIME,
	TIME_TOGGLE,
} from "../../actions/actions"
import moment from "moment"
import { getSportsConfig } from "../../../utility/constants/sportConfig"

// **  Initial State
const initialState = JSON.parse(localStorage.getItem("time") ?? "{}")
initialState.isPaused ??= true
initialState.timeElapsed ??= 0
initialState.fullTimeElapsed ??= 0
initialState.halftime ??= 0
initialState.notStarted ??= true

// ** Reducer
export default (state = initialState, action) => {
	switch (action.type) {
		case TIME_TOGGLE: {
			const { isPaused, team } = action

			const newState = { ...state, isPaused }
			newState.notStarted = false

			// If user just paused, remove startTime and update timeElapsed
			if (isPaused) {
				if (state.startTime) {
					if (team) newState.team = team
					newState.timeElapsed += moment.now() - state.startTime
					delete newState.startTime
				}
			} else {
				delete newState.team
				newState.startTime = moment.now()
			}

			if (!newState.timeElapsed) {
				console.error("CUSTOM CAUGHT ERROR. The state was:", state)
			}

			localStorage.setItem("time", JSON.stringify(newState))
			return newState
		}
		case TIME_HALFTIME: {
			const newTime = state.timeElapsed + moment.now() - state.startTime
			const maxHalftime = moment(0).minutes(localStorage.getItem("halftime"))

			localStorage.removeItem("pause-homeTeam")
			localStorage.removeItem("pause-awayTeam")

			const newState = {
				isPaused: true,
				timeElapsed: 0,
				notStarted: true,
				halftime: (state.halftime ?? 0) + 1,
				fullTimeElapsed:
					(state.fullTimeElapsed ?? 0) +
					(newTime < maxHalftime ? newTime : maxHalftime),
			}

			localStorage.setItem("time", JSON.stringify(newState))
			return newState
		}
		case TIME_OVERTIME: {
			const { isDone } = action

			const newTime = state.timeElapsed + moment.now() - state.startTime
			const maxOvertime = moment(0).minutes(
				getSportsConfig(localStorage.getItem("sport")).overtimeMinutes
			)
			const fullTimeElapsed =
				isDone && state.overtime
					? (state.fullTimeElapsed ?? 0) +
					  (newTime < maxOvertime ? newTime : maxOvertime)
					: state.fullTimeElapsed ?? 0

			const newState = {
				isPaused: !!isDone,
				notStarted: !!isDone,
				halftime: state.halftime,
				overtime: state.overtime ?? 0,
				timeElapsed: 0,
				fullTimeElapsed,
			}

			if (!isDone) {
				newState.overtime += 1
				newState.startTime = moment.now()
			}

			localStorage.setItem("time", JSON.stringify(newState))
			return newState
		}
		case TIME_FROM_SOCKET: {
			const { time } = action
			if (!time || Object.keys(time) == 0) return state
			localStorage.setItem("time", JSON.stringify(time))
			return time
		}
		case ALL_RESET:
			localStorage.removeItem("time")
			return {
				isPaused: true,
				timeElapsed: 0,
				fullTimeElapsed: 0,
				notStarted: true,
				halftime: 0,
			}
		default:
			return state
	}
}

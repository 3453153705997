/* eslint-disable no-console */
import { io } from "socket.io-client"
import { BASE_URL } from "../index"
import {
	addEvent,
	editEvent,
	forceIsLoading,
	forceReRender,
	removeEvent,
	setIsDisconnected,
	setIsPaused,
	setPenaltiesOrEnd,
	setScoreCounter,
	setTimeFromSocket,
	toggleOvertime,
	updateScoreCounter,
} from "../../redux/actions/actions"
import { getTime, toggleTime } from "./functions"
import { getSportsConfig } from "../../utility/constants/sportConfig"
import { onPostGetEvents } from "../../utility/Utils"
import { postGetEvents } from "../"

import { shallowEqual } from "react-redux"
import moment from "moment"




class SocketService {
	constructor() {
		this.setDispatch = this.setDispatch.bind(this)
		this.initSocket = this.initSocket.bind(this)
		this.removeSocket = this.removeSocket.bind(this)
		this.initSocketListeners = this.initSocketListeners.bind(this)
		this.onEmit = this.onEmit.bind(this)
	}

	setDispatch(dispatch) {
		this.dispatch = dispatch
		/* this.initSocketListeners() */
	}

	refresh() {
		this.body = {
			sport: localStorage.getItem("sport"),
			game:
				JSON.parse(localStorage.getItem("gameDetails") ?? "{}").gameId ?? "",
		}

		postGetEvents(this.body).then((response) =>
		onPostGetEvents(this.body, this.dispatch, response)
	)
	}

	initSocket() {
		console.log("Init socket")
		this.sport = localStorage.getItem("sport")
		this.isInternetLost = false

		if (!this.sport || !getSportsConfig(this.sport).hasSockets) return

		try {
			this.gameId = JSON.parse(localStorage.getItem("gameDetails"))?.gameId

			if (localStorage.getItem("spectate")) {
				this.socket = io(BASE_URL, {
					query: `gameId=${this.gameId}`,
					transports: ["websocket", "polling"] 
				})
			} else {
				this.socket = io(BASE_URL, {
					query: `gameId=${this.gameId}&token=${localStorage.getItem(
						"token"
					)}`,
					transports: ["websocket", "polling"] 

				})
				console.log("Socket connection")
			}
			this.homeTeam = JSON.parse(
				localStorage.getItem("homeTeam") ?? "{}"
			)?.name

			this.socket.on("connect", () => {
				
				if (!this.isDisconnected) return
				this.isDisconnected = false
				this.dispatch(setIsDisconnected(false))
/* 				onConnectedToSocket(this.onEmit)
 */	
					this.refresh()
				
			})
			this.socket.on("connect_error", () => {
				if (this.isDisconnected) return
				this.isDisconnected = true
				this.dispatch(setIsDisconnected(true))
				this.isInternetLost = true
				
			})

			this.socket.on("disconnect", ( ) => {
				console.log("Disconnected")
			})
		} catch (e) {
			console.log("Something went wrong when initializing a socket", e)
		}
	}

	removeSocket() {
		this.socket?.disconnect()
		this.socket = undefined
		this.sport = undefined
		this.homeTeam = undefined
		this.gameId = undefined
		/* this.initSocketListeners() */
	}

	async initSocketListeners() {
		this.initSocket()

		/* while (!this.socket) {
			await new Promise((resolve) => setTimeout(resolve, 4000))
			this.initSocket()
		} */

		this.socket.on("game", (data) => {
			if(!getSportsConfig(this.sport).doesntHaveTime && data.joinedRoom){
				getTime()
			}
		})

		this.socket.on("send-event-echo", ({ data, id }) => {
			this.dispatch(addEvent({ ...data, id }))
		})
		this.socket.on("delete-event-echo", ({ id }) => {
			const { score } =
				JSON.parse(localStorage.getItem("events")).find(
					(e) => e.id === id
				) ?? {}
			if (score) this.dispatch(updateScoreCounter(score.team, -score.value))

			this.dispatch(removeEvent(id))
		})
		this.socket.on("update-event-echo", ({ data, id }) => {
			this.dispatch(editEvent({ ...data, id }))
		})
		this.socket.on("toggle-time-echo", (response) =>
			onToggleTimeEcho(response, this.dispatch)
		)
		this.socket.on("toggle-result-echo", ({ result }) => {
			const { sets, currentResult } = result
			if (currentResult) this.dispatch(setScoreCounter(currentResult))
			const oldSets = JSON.parse(localStorage.getItem("sets") ?? "{}")
			if (
				sets &&
				(!shallowEqual(sets.homeTeam, oldSets.homeTeam) ||
					!shallowEqual(sets.awayTeam, oldSets.awayTeam))
			) {
				localStorage.setItem("sets", JSON.stringify(sets))
				this.dispatch(forceReRender())
			}
		})

		this.socket.on("get-time-echo", (time) => {
			if (time == null) return this.dispatch(forceIsLoading(false))

			const { penaltiesOrEnd, pauses } = time
			delete time.pauses
			if (penaltiesOrEnd) delete time.penaltiesOrEnd

			if (!shallowEqual(time, JSON.parse(localStorage.getItem("time"))))
				this.dispatch(setTimeFromSocket(time))
			if (
				pauses?.homeTeam &&
				pauses?.homeTeam !== localStorage.getItem("pause-homeTeam")
			)
				localStorage.setItem("pause-homeTeam", pauses.homeTeam)
			if (
				pauses?.awayTeam &&
				pauses?.awayTeam !== localStorage.getItem("pause-awayTeam")
			)
				localStorage.setItem("pause-awayTeam", pauses.awayTeam)
			if (
				penaltiesOrEnd &&
				penaltiesOrEnd !== localStorage.getItem("penaltiesOrEnd") &&
				localStorage.getItem("penaltiesOrEnd") != "done"
			)
				this.dispatch(setPenaltiesOrEnd(penaltiesOrEnd))
			this.dispatch(forceIsLoading(false))
		})

		this.socket.on("end-game-echo", () => {
			this.dispatch(setPenaltiesOrEnd("done"))
		})

		this.socket.on("forfeit-echo", (body) => {
			localStorage.setItem("forfeitBody", JSON.stringify(body))
			this.dispatch(setPenaltiesOrEnd("forfeit"))
		})
	}

	toggleResult() {}

	onEmit(path, body = {}, addGameId = true, addSport = true) {
		if (!this.sport || !this.gameId) {
			console.log(
				"Cannot send emit until gameId and sport and socket are initialized. Body was:",
				body
			)
			return
		}
		const newBody = { ...body }
		if (addSport) newBody.sport = this.sport
		if (addGameId) newBody.game = this.gameId

		if (body?.team) {
			newBody.isHomeTeam = this.homeTeam === body.team
			newBody.team = JSON.parse(
				localStorage.getItem(
					this.homeTeam === body.team ? "homeTeam" : "awayTeam"
				)
			).teamId
		}

		if (this.isDisconnected) {
			handleEmitWhenDisconnected(path, newBody, this.dispatch)
			return () => null
		}

		console.log("emitting", path, newBody)
		return this.socket?.emit(path, newBody)
	}
}

const socketService = new SocketService()

const { removeSocket, setDispatch, onEmit, initSocketListeners } = socketService
export { removeSocket, setDispatch, onEmit, initSocketListeners }

const onToggleTimeEcho = ({ time }, dispatch) => {
	const {
		isPaused,
		teamName,
		toggleOvertime: shouldToggleOvertime,
		penaltiesOrEnd,
	} = time
	localStorage.setItem("time", JSON.stringify(time))

	localStorage.setItem("pause-homeTeam", JSON.parse(time.pauses?.homeTeam ?? "0"))
	localStorage.setItem("pause-awayTeam", JSON.parse(time.pauses?.awayTeam ?? "0"))

	if (shouldToggleOvertime) {
		dispatch(toggleOvertime())
	} else if (penaltiesOrEnd) {
		dispatch(setIsPaused(isPaused))
		dispatch(setPenaltiesOrEnd(penaltiesOrEnd))
	} else {
		console.log("Dispatching setIsPaused", time)
		dispatch(setIsPaused(isPaused, teamName))
	}
}

// I know its a messy place to put it, but the project is almost over
// The following function only runs if user is disconnected, all it does is saves what emits happened in a key value pair
const handleEmitWhenDisconnected = (path, body, dispatch) => {
	const disconnectedEmits = JSON.parse(
		localStorage.getItem("disconnectedEmits") ?? "[]"
	)

	switch (path) {
		case "send-event":
			dispatch(addEvent(body, false))
			disconnectedEmits.push({ path, body })
			localStorage.setItem(
				"disconnectedEmits",
				JSON.stringify(disconnectedEmits)
			)
			break
		case "toggle-time":
			onToggleTimeEcho(body, dispatch)

			let timeEmit = disconnectedEmits.find((e) => e.path == "toggle-time")
			if (timeEmit == null) {
				disconnectedEmits.push({ path, body })
			} else {
				timeEmit.body = body
			}

			localStorage.setItem(
				"disconnectedEmits",
				JSON.stringify(disconnectedEmits)
			)
			break
		default:
			console.log("User tried to emit when disconnected", path, body)
			break
	}
}

const onConnectedToSocket = (onEmit) => {
	const disconnectedEmits = JSON.parse(
		localStorage.getItem("disconnectedEmits") ?? "[]"
	)

	for (let i = 0; i < disconnectedEmits.length; i++) {
		const { path, body } = disconnectedEmits[i]
		onEmit(path, body)
	}
	localStorage.removeItem("disconnectedEmits")
}

import { getCurrentTime, getHalftimeCharacter } from "../../utility/Utils"
import { getSportsConfig } from "../../utility/constants/sportConfig"
import { gridColumns, gridRows } from "../../utility/constants/gridSize"
import { onEmit } from "./index"
import moment from "moment"

// const reset = false
export const sendEvent = (params, forceTime = false) =>
	onEmit("send-event", getEventBody(params, forceTime))
export const deleteEvent = (eventId) => onEmit("delete-event", { eventId }, false)
export const editEvent = (params) =>
	onEmit("update-event", getEventBody(params, true))

export const toggleTime = ({
	isPaused,
	teamName,
	toggleOvertime,
	penaltiesOrEnd,
}) => {
	// if (reset) {
	// 	onEmit(
	// 		"toggle-time",
	// 		{
	// 			time: {
	// 				halftime: 0,
	// 				fullTimeElapsed: 0,
	// 				isPaused: true,
	// 				notStarted: true,
	// 			},
	// 		},
	// 		false
	// 	)
	// 	return
	// }
	const time = JSON.parse(localStorage.getItem("time") ?? "{}")
	time.pauses = {
		homeTeam: JSON.parse(localStorage.getItem("pause-homeTeam") ?? 0),
		awayTeam: JSON.parse(localStorage.getItem("pause-awayTeam") ?? 0),
	}
	if (isPaused) {
		time.timeElapsed ??= 0
		time.timeElapsed += moment.now() - time.startTime
		delete time.startTime
	} else {
		time.startTime = moment.now()
	}
	if (toggleOvertime) {
		delete time.startTime
	}

	time.halftime ??= 0
	delete time.toggleOvertime
	if (toggleOvertime) {
		onEmit("toggle-time", { time: { ...time, toggleOvertime } }, false)
	} else if (penaltiesOrEnd) {
		onEmit("toggle-time", { time: { ...time, isPaused, penaltiesOrEnd } }, false)
	} else if (teamName) {
		const isHomeTeam =
			JSON.parse(localStorage.getItem("homeTeam") ?? "{}").name == teamName
		time.pauses[isHomeTeam ? "homeTeam" : "awayTeam"] += 1
		onEmit("toggle-time", { time: { ...time, isPaused, teamName } }, false)
	} else {
		onEmit("toggle-time", { time: { ...time, isPaused } }, false)
	}
}
export const getTime = () => onEmit("get-time", {}, false)

export const endGame = () => {
	const isEndingAgain = JSON.parse(localStorage.getItem("isDone") ?? "false")
	if (isEndingAgain) {
		const body = { isUpdate: true }
		if (localStorage.getItem("sport").toLowerCase() == "volleyball") {
			body.oldSets = JSON.parse(localStorage.getItem("old-sets") ?? "{}")
			body.newSets = JSON.parse(localStorage.getItem("sets") ?? "{}")

		} else {
			body.oldHomeScore = JSON.parse(
				localStorage.getItem("oldScore-homeTeam") ?? 0
			)
			body.newHomeScore = JSON.parse(
				localStorage.getItem("score-homeTeam") ?? 0
			)
			body.oldAwayScore = JSON.parse(
				localStorage.getItem("oldScore-awayTeam") ?? 0
			)
			body.newAwayScore = JSON.parse(
				localStorage.getItem("score-awayTeam") ?? 0
			)
		}
		onEmit("end-game", body)
	} else {
		onEmit("end-game", {})
	}
}

export const updateVolleyballResult = (currentResult, sets) => {
	onEmit("toggle-result", {
		result: {
			currentResult,
			sets:
				sets ??
				JSON.parse(
					localStorage.getItem("sets") ??
					// eslint-disable-next-line prettier/prettier
					"{\"homeTeam\": [], \"awayTeam\": []}"
				),
		},
	})
}

export const forfeitMatch = (params) => onEmit("forfeit", params, false, false)

// * Helper functions
function getEventBody(params, isEdit = false) {
	const {
		team,
		eventKey,
		playerId,
		player2Id,
		tshirtNumber,
		tshirtNumber2,
		coordinates,
		comment,
		eventId,
		order,
	} = params

	const fullTime =
		!isEdit || (!params.time && !params.quarter) ? getTimeAndQuarter() : params
	const { time } = fullTime
	let { quarter } = fullTime

	const events = JSON.parse(localStorage.getItem("events") ?? "[]")
	const body = {
		comment,
		team,
		type: eventKey,
	}

	if (time && quarter) {
		const quarterEnding = quarter.substr(quarter.length - 1)
		const quarterNum = parseInt(quarter)

		if (quarterEnding != "Q") {
			if (
				quarterNum >
				getSportsConfig(localStorage.getItem("sport")).numOfHalftimes
			) {
				quarter = `${quarterNum - 1}${quarterEnding}`
			} else if (quarterNum < 1) {
				quarter = `1${quarterEnding}`
			}
		}

		body.time = time
		body.quarter = quarter
	} else {
		body.order = order ?? { order: 0, ...events[events.length - 1] }.order + 1
	}

	if (playerId) {
		body.player = playerId
		body.tshirtNumber = tshirtNumber
		if (player2Id) {
			const homeTeam = JSON.parse(localStorage.getItem("homeTeam") ?? "{}")
			const awayTeam = JSON.parse(localStorage.getItem("awayTeam") ?? "{}")
			body.player2 = player2Id
			body.tshirtNumber2 = tshirtNumber2

			if (homeTeam.players.find((e) => e.playerId === player2Id)) {
				body.team2 = homeTeam.teamId
			} else {
				body.team2 = awayTeam.teamId
			}
		}
	}

	if (eventId) body.eventId = eventId

	setCoordinates(coordinates, eventKey, body)
	return body
}

function getTimeAndQuarter() {
	const { overtime, halftime } = JSON.parse(localStorage.getItem("time") ?? "{}")
	const halftimeCharacter = getHalftimeCharacter()
	if (!halftimeCharacter) return {}

	let quarter = ""
	if (overtime) quarter = `${overtime}O`
	else quarter = parseInt(halftime ?? 0) + 1 + halftimeCharacter

	return {
		time: getCurrentTime().fulltime,
		quarter,
	}
}

function setCoordinates(coordinates = {}, eventKey, body) {
	const { x, y } = coordinates
	if (eventKey === "Corner") {
		if (x != null && y != null)
			body.coordinates = {
				x: (x ?? 0) / gridColumns < 0.5 ? 0 : gridColumns - 1,
				y: (y ?? 0) / gridRows < 0.5 ? 0 : gridRows - 1,
			}
		return
	}
	const { quick, normal } = getSportsConfig(localStorage.getItem("sport")).events
	const selectedEvent =
		normal.find((ev) => ev.key === eventKey) ??
		quick.find((ev) => ev.key === eventKey)

	if (selectedEvent?.coordinates) {
		if (!body.isHomeTeam) {
			body.coordinates = selectedEvent.coordinates
		} else {
			body.coordinates = {
				x: selectedEvent.coordinates.x,
				y: gridColumns - selectedEvent.coordinates.y - 1,
			}
		}

		return
	}

	if (x != null && y != null) body.coordinates = coordinates
}

export default {
	layout: "discipline",
	contestantName: "athlete",
	contestantsName: "athletes",
	doesntHaveTime: true,

	defaultDiscipline: {
		fields: [
			{ name: "Vrijeme", type: "time" },
			{ name: "Napomena", type: "text" },
		],
	},

	disciplines: [
		{
			name: "4x50m slobodno štafeta",
			fields: [
				{ name: "Vrijeme 1", type: "time" },
				{ name: "Vrijeme 2", type: "time" },
				{ name: "Vrijeme 3", type: "time" },
				{ name: "Vrijeme 4", type: "time" },
			],
		},
		{
			name: "4x50m mješovito štafeta",
			fields: [
				{ name: "Vrijeme 1", type: "time" },
				{ name: "Vrijeme 2", type: "time" },
				{ name: "Vrijeme 3", type: "time" },
				{ name: "Vrijeme 4", type: "time" },
			],
		},
		{
			name: "50m leđno",
			fields: [
				{ name: "Vrijeme", type: "time" },
				{ name: "Napomena", type: "text" },
			],
		},
		{
			name: "50m leđno",
			fields: [
				{ name: "Vrijeme", type: "time" },
				{ name: "Napomena", type: "text" },
			],
		},
		{
			name: "50m leptir",
			fields: [
				{ name: "Vrijeme", type: "time" },
				{ name: "Napomena", type: "text" },
			],
		},
		{
			name: "100m slobodno",
			fields: [
				{ name: "Vrijeme", type: "time" },
				{ name: "Napomena", type: "text" },
			],
		},
		{
			name: "50m prsno",
			fields: [
				{ name: "Vrijeme", type: "time" },
				{ name: "Napomena", type: "text" },
			],
		},
		{
			name: "100m leptir",
			fields: [
				{ name: "Vrijeme", type: "time" },
				{ name: "Napomena", type: "text" },
			],
		},
		{
			name: "50m slobodno",
			fields: [
				{ name: "Vrijeme", type: "time" },
				{ name: "Napomena", type: "text" },
			],
		},
	],
}

export default {
	quick: [
		{
			name: "2 promašaj",
			comment:
				"Neprecizan pokušaj za dva poena. Promašio je sada šut ${name} (${teamName}).",
			key: "2pt miss",
			types: ["A"],
		},
		{
			name: "3 promašaj",
			comment:
				"Bio je ovo pokušaj za tri poena, ali ${name} (${teamName}) je bio neprecizan.",
			key: "3pt miss",
			types: ["A"],
		},
		{
			name: "Skok",
			comment:
				// They probably want teamName here instead of tshirtNumber
				"${name} je bio najviši i osvojio je sada loptu za ${teamName}.",
			key: "ReboundDefensive",
			types: ["A", "B"],
		},
		{
			name: "Skok u napadu",
			comment: "Odličan ofenzivan skok upisao je sada ${name} (${teamName}).",
			key: "ReboundOffensive",
			types: ["A"],
		},
	],
	normal: [
		{
			name: "1 promašaj",
			comment:
				// They probably want teamName here instead of tshirtNumber
				"Promašeno slobodno bacanje za ${teamName}. Neprecizan je bio ${name}.",
			key: "1pt miss",
			types: ["A"],
		},
		{
			name: "1 zabijeni",
			comment:
				"Precizan je sada bio ${name} (${teamName}) s linije za slobodna bacanja.",
			increment: 1,
			key: "1pt",
			types: ["A", "B", "C"],
		},
		{
			name: "2 zabijeni",
			comment: "Koš. ${name} (${teamName}) zabija za dva poena.",
			followup: {
				type: "teamPlayers",
				allowIgnore: true,
				comment: "${name} (${teamName}) mu je asistirao.",
			},
			increment: 2,
			key: "2pt",
			types: ["A", "B", "C"],
		},
		{
			name: "3 zabijeni",
			comment:
				"Tricaaa. Odmjeren udarac uputio je ${name} (${teamName}) i pogodio za tri poena.",
			followup: {
				type: "teamPlayers",
				allowIgnore: true,
				comment: "${name} (${teamName}) mu je asistirao.",
			},
			increment: 3,
			key: "3pt",
			types: ["A", "B", "C"],
		},
		{
			name: "Prekršaj",
			comment: "Sudac se oglasio. Prekršaj je napravio ${name} (${teamName}).",
			key: "Foul",
			types: ["A", "B"],
		},
		{
			name: "Prekršaj u napadu",
			comment:
				"Prekršaj u napadu. Neoprezan je bio sada ${name} (${teamName}).",
			key: "Foul",
			types: ["A"],
		},
		{
			name: "Blokada",
			comment:
				"Blokadaaa. Fantastična obrambena reakcija i odličan defenzivan potez napravio je sada ${name} (${teamName}).",
			key: "Block",
			types: ["A", "B"],
		},
		{
			name: "Izgubljena lopta",
			followup: {
				type: "enemyPlayers",
				allowIgnore: true,
				comment: "${name} (${teamName}) mu je ukrao loptu.",
			},
			comment:
				"Kriva je sada procijenio i loše odigrao ${name} (${teamName}).",
			key: "Turnover",
			types: ["A", "B"],
		},
		{
			name: "Tehnička",
			comment:
				"Tehnička. Pretjerao je sada malo ${name} (${teamName}) i to su sankcionirali suci.",
			key: "Technical",
			types: ["A", "B"],
		},
	],
}

import { EventsVolleyball } from "../../events/index"

export default {
	layout: "volleyball",
	events: EventsVolleyball,
	field: "court-volleyball",
	sets: 5,
	doesntHaveTime: true,
	incrementThroughSocket: true,

	playerNumbers: true,
	hasBench: true,
	hasCaptain: true,
	hasMvp: true,
	hasSockets: true,
	hasPauses: true,
	teamPausesAllowed: 10,
	twoJerseyColors: true,


	formations: {
		default: [
			{ name: "1", coordinate: { x: 6, y: 11 } },
			{ name: "2", coordinate: { x: 15, y: 18 } },
			{ name: "3", coordinate: { x: 15, y: 11 } },
			{ name: "4", coordinate: { x: 15, y: 4 } },
			{ name: "5", coordinate: { x: 6, y: 4 } },
			{ name: "6", coordinate: { x: 6, y: 18 } },
		],
	},

	teamComments: [
		"Minuta odmora za ${name}. Sada je na izborniku da pripremi svoju ekipu najbolje što može za nastavak.",
	],
}

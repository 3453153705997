import { EventsBasketball3x3 } from "../../events/index"

export default {
	layout: "court",
	events: EventsBasketball3x3,
	field: "court-basketball3x3",
	scorePoints: [1, 2],
	reachPoints: 21,
	numOfHalftimes: 2,
	overtimeMinutes: 5,

	formations: {
		default: 
			[
				{ name: "1", coordinate: { x: 11, y: 7} },
				{ name: "2", coordinate: { x: 11, y: 11 } },
				{ name: "3", coordinate: { x: 11, y: 15} },
			]
	},

	hasBench: true,
	playerNumbers: true,
	hasCaptain: true,
	hasMvp: true,
	hasSockets: true,
	hasPauses: true,
	teamPausesAllowed: 1,

	teamComments: [
		"Minuta odmora za ${name}. Sada je na izborniku da pripremi svoju ekipu najbolje što može za nastavak.",
		"${name} ulazi u bonus. Svaki sljedeći prekršaj znači slobodna bacanja za protivnika.",
		"${name} u potpunosti dominira ovom utakmicom. Odlična utakmica za njih.",
	],
}

import {
	ALL_INIT,
	ALL_RESET,
	SC_RESET,
	SC_SET,
	SC_UPDATE,
} from "../../actions/actions"

// **  Initial State
const initialState = {
	homeTeam: JSON.parse(localStorage.getItem("score-homeTeam") ?? "0"),
	awayTeam: JSON.parse(localStorage.getItem("score-awayTeam") ?? "0"),
}

// ** Reducer
export default (state = initialState, action) => {
	switch (action.type) {
		case SC_SET:
			const { result } = action
			localStorage.setItem("score-homeTeam", result.homeTeam)
			localStorage.setItem("score-awayTeam", result.awayTeam)
			return result
		case SC_UPDATE:
			const { team, points } = action
			const newPoints = state[team] + points >= 0 ? state[team] + points : 0
			localStorage.setItem(`score-${team}`, newPoints)
			return { ...state, [team]: newPoints }
		case SC_RESET:
		case ALL_RESET:
			localStorage.removeItem("score-awayTeam")
			localStorage.removeItem("score-homeTeam")
			return { homeTeam: 0, awayTeam: 0 }
		case ALL_INIT:
			return {
				homeTeam: JSON.parse(localStorage.getItem("score-homeTeam") ?? "0"),
				awayTeam: JSON.parse(localStorage.getItem("score-awayTeam") ?? "0"),
			}
		default:
			return state
	}
}

import { getSportsConfig } from "../../../utility/constants/sportConfig"
import { getAllEvents } from "../../../utility/Utils"
import {
	ALL_INIT,
	ALL_RESET,
	EVENTS_ADD,
	EVENTS_EDIT,
	EVENTS_INSERT_SORT,
	EVENTS_REMOVE,
} from "../../actions/actions"

// **  Initial State
const initialState = []

// ** Reducer
export default (state = initialState, action) => {
	switch (action.type) {
		case EVENTS_ADD: {
			const newState = [...state]
			const newEvent = getTableEventFromAction(action.params)
			insertInSortedArray(newState, newEvent)

			if (action.saveLocally)
				localStorage.setItem("events", JSON.stringify(newState))
			return newState
		}
		case EVENTS_EDIT: {
			const newState = [...state]
			const index = newState.findIndex(
				(event) => event.id === action.params.id
			)
			newState.splice(index, 1)
			insertInSortedArray(newState, getTableEventFromAction(action.params))

			localStorage.setItem("events", JSON.stringify(newState))
			return newState
		}
		case EVENTS_REMOVE: {
			const { id } = action
			const newState = [...state]
			const index = newState.findIndex((e) => e.id === id)
			if (index > -1) newState.splice(index, 1)[0]

			localStorage.setItem("events", JSON.stringify(newState))
			return newState
		}
		case ALL_INIT: {
			if (state.length > 0) return state
			return JSON.parse(localStorage.getItem("events") ?? "[]")
		}
		case ALL_RESET: {
			localStorage.removeItem("events")
			return initialState
		}
		case EVENTS_INSERT_SORT: {
			const { array } = action

			if (array.length === 0) return state

			const newState = [array[0]]
			for (let x = 1; x < array.length; x++)
				insertInSortedArray(newState, array[x])

			localStorage.setItem("events", JSON.stringify(newState))
			return newState
		}
		default:
			return state
	}
}

// ** Helper Functions
function getTableEventFromAction(params) {
	const {
		id,
		comment,
		quarter,
		time,
		type,
		isHomeTeam,
		player: playerId,
		player2: player2Id,
		order,
		coordinates,
	} = params

	const allEvents = getAllEvents()
	let selectedEvent = allEvents.find((ev) => ev.key === type)

	if (!selectedEvent) {
		const penaltyEvents = normal.filter((ev) => ev.isPenalty)

		selectedEvent = penaltyEvents.find(
			(ev) =>
				"Penalty" + ev.key.substr(parseInt(ev.key).toString().length + 1) ==
				type
		)
		if (selectedEvent) {
			selectedEvent = {
				...selectedEvent,
				name:
					"Penal " +
					selectedEvent.name.substr(
						parseInt(selectedEvent.name).toString().length + 1
					),
			}
		} else {
			selectedEvent = {}
		}
	}

	const event = {
		id,
		comment,
		event: selectedEvent.name,
	}

	if (time) {
		event.quarter = quarter
		event.time = time
	} else {
		event.order = order
	}

	// If its comment, then we dont need to get any more data
	if (type.toLowerCase() === "comment") return { ...event, event: "Komentar" }

	const { name: team, players } = JSON.parse(
		localStorage.getItem(isHomeTeam ? "homeTeam" : "awayTeam")
	)
	// If its team comment, then we dont need to get any more data
	if (type.toLowerCase() === "team tomment")
		return { ...event, team, event: "Ekipni Komentar" }

	const { name, surname } =
		players.find((player) => player.playerId === playerId) ?? {}
	if (selectedEvent.increment) {
		// if selected.increment is -1 then its a score for the opposite team
		if (selectedEvent.increment > 0) {
			const team = isHomeTeam ? "homeTeam" : "awayTeam"
			event.score = { team, value: selectedEvent.increment }
		} else {
			const team = !isHomeTeam ? "homeTeam" : "awayTeam"
			event.score = { team, value: -selectedEvent.increment }
		}
	}

	return {
		...event,
		team: team,
		playerName: name ? `${name} ${surname}` : "",
		playerId,
		player2Id,
		coordinates,
	}
}

export const insertInSortedArray = (array, editedEvent) => {
	if (!editedEvent.quarter || !editedEvent.time || editedEvent.quarter === "P") {
		if (!editedEvent.order) return array.push(editedEvent)

		for (let i = 0; i < array.length; i++) {
			if (array[i].order > editedEvent.order) {
				array.splice(i, 0, editedEvent)
				return
			}
		}

		array.push(editedEvent)
		return
	}

	const selQNum = parseInt(editedEvent.quarter)
	const selQChar = editedEvent.quarter.substr(editedEvent.quarter.length - 1, 1)
	const selTimeMin = parseInt(editedEvent.time)
	const selTimeSec = parseInt(editedEvent.time.substr(3))

	const selQIsOvertime = selQChar === "O"
	let index
	let i

	for (i = 0; i < array.length; i++) {
		const { quarter } = array[i]
		const quarterChar = quarter.substr(quarter.length - 1, 1)

		// If we didnt enter overtime but our quarter requires overtime then continue
		if (selQIsOvertime && quarterChar !== "O") continue
		// If we entered overtime but our quarter requires not overtime then break
		if (!selQIsOvertime && quarterChar === "O") {
			return array.splice(i, 0, editedEvent)
		}

		if (selQNum <= parseInt(quarter)) break
	}

	for (; i < array.length; i++) {
		const { quarter, time } = array[i]
		if (quarter !== editedEvent.quarter) {
			index = i
			break
		}

		if (
			selTimeMin < parseInt(time) ||
			(selTimeMin === parseInt(time) && selTimeSec <= parseInt(time.substr(3)))
		) {
			index = i
			break
		}
	}

	array.splice(index ?? array.length, 0, editedEvent)
}

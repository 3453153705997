import { ALL_RESET, PENALTIES_OR_END_SET } from "../../actions/actions"

// **  Initial State
const initialState = localStorage.getItem("penaltiesOrEnd") ?? ""

// ** Reducer
export default (state = initialState, action) => {
	switch (action.type) {
		case PENALTIES_OR_END_SET:
			const { value } = action
			localStorage.setItem("penaltiesOrEnd", value)
			return value
		case ALL_RESET:
			localStorage.removeItem("penaltiesOrEnd")
			return ""
		default:
			return state
	}
}

export default {
	quick: [
		{
			name: "1 promašaj",
			comment:
				"Neprecizan pokušaj za jedan poen. Promašio je sada šut ${name} (${teamName}).",
			key: "1pt miss",
			types: ["A"],
		},
		{
			name: "2 promašaj",
			comment:
				"Bio je ovo pokušaj za dva poena, ali ${name} (${teamName}) je bio neprecizan.",
			key: "2pt miss",
			types: ["A"],
		},
		{
			name: "Prekršaj",
			comment: "Sudac se oglasio. Prekršaj je napravio ${name} (${teamName}).",
			key: "Foul",
			types: ["A", "B"],
		},
		{
			name: "Skok",
			// They probably want teamName here instead of tshirtNumber
			comment:
				"${name} je bio najviši i osvojio je sada loptu za (${teamName}).",
			key: "ReboundDefensive",
			types: ["A", "B"],
		},
	],
	normal: [
		{
			name: "1 zabijeni",
			followup: {
				type: "teamPlayers",
				allowIgnore: true,
				comment: "${name} (${teamName}) mu je asistirao.",
			},
			comment: "Koš. ${name} (${teamName}) zabija za jedan poen.",
			increment: 1,
			key: "1pt",
			types: ["A", "B", "C"],
		},
		{
			name: "2 zabijeni",
			followup: {
				type: "teamPlayers",
				allowIgnore: true,
				comment: "${name} (${teamName}) mu je asistirao.",
			},
			comment:
				"Za dvaa. Odmjeren udarac uputio je ${name} (${teamName}) i pogodio za dva poena.",
			increment: 2,
			key: "2pt",
			types: ["A", "B", "C"],
		},
		{
			name: "Zabijeno slobodno",
			comment:
				"Precizan je sada bio ${name} (${teamName}) s linije za slobodna bacanja.",
			key: "FT",
			increment: 1,
			types: ["A", "B", "C"],
		},
		{
			name: "Promašeno slobodno",
			// They probably want teamName here instead of tshirtNumber
			comment:
				"Promašeno slobodno bacanje za (${teamName}). Neprecizan je bio ${name}.",
			key: "FT miss",
			types: ["A"],
		},
		{
			name: "Prekršaj u napadu",
			followup: {
				type: "enemyPlayers",
				allowIgnore: true,
				comment:
					"Prekršaj u napadu. Neoprezan je bio sada ${name} (${teamName}).",
			},
			comment: "${name} (${teamName}) je počinio faul.",
			key: "Foul",
			types: ["A"],
		},
		{
			name: "Skok u napadu",
			comment: "Odličan ofenzivan skok upisao je sada ${name} (${teamName}).",
			key: "ReboundOffensive",
			types: ["A"],
		},
		{
			name: "Blokada",
			comment:
				"Blokadaaa. Fantastična obrambena reakcija i odličan defenzivan potez napravio je sada ${name} (${teamName}).",
			key: "Block",
			types: ["A", "B"],
		},
		{
			name: "Izgubljena lopta",
			followup: {
				type: "enemyPlayers",
				allowIgnore: true,
				comment: "${name} (${teamName}) mu je ukrao loptu.",
			},
			comment:
				"Krivo je sada procijenio i loše odigrao ${name} (${teamName}).",
			key: "Turnover",
			types: ["A", "B"],
		},
		{
			name: "Tehnička",
			comment:
				"Tehnička. Pretjerao je sada malo ${name} (${teamName}) i to su sankcionirali suci.",
			key: "Technical",
			types: ["A", "B"],
		},
	],
}

export default {
	quick: [
		{
			name: "U Golu",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali siguran je na golu bio ${name} (${teamName}).",
			},
			// They probably want teamName here instead of tshirtNumber
			comment:
				"Bio je ovo dobar pokušaj za ${teamName}. Udarac je uputio ${name},",
			key: "Shot on target",
			types: ["A"],
		},
		{
			name: "Van Gola",
			comment:
				"Pokušao je sada ${name} (${teamName}), ali udarac je bio neprecizan i završio je pored gola.",
			key: "Shot off target",
			types: ["A"],
		},
		{
			name: "Blok",
			// They probably want teamName here instead of tshirtNumber
			comment:
				"Pokušaj za (${teamName}), ali udarac koji je uputio ${name} je završio u bloku.",
			key: "Blocked shot",
			types: ["A"],
		},
		{
			name: "Korner",
			// They probably want teamName here instead of tshirtNumber
			comment: "Bit će sada korner za (${teamName}).",
			key: "Corner",
			types: ["A"],
		},
	],
	normal: [
		{
			name: "Gol",
			followup: {
				type: "teamPlayers",
				allowIgnore: true,
				comment: "Asistirao je ${name} (${teamName}).",
			},
			increment: 1,
			comment: "Gooooool. Pogodak je postigao ${name} (${teamName}).",
			key: "Goal",
			types: ["A", "B", "C"],
		},
		{
			name: "Autogol",
			followup: {
				type: "enemyPlayers",
				allowIgnore: true,
				comment:
					"Na to ga je potaknuo ${name} (${teamName}) iz protivničke ekipe.",
			},
			increment: -1,
			comment:
				"AUTOGOL! Nespretnom reakcijom ${name} (${teamName}) posprema loptu u vlastitu mrežu.",
			key: "Own goal",
			types: ["A", "B", "C"],
		},
		{
			name: "Prekršaj",
			followup: {
				type: "event",
				events: ["Faul", "Ruka"],
				comment: "${event}.",
			},
			comment: "${name} (${teamName}) je počinio prekršaj:",
			key: "Foul",
			types: ["A", "B"],
		},
		{
			name: "Žuti karton",
			followup: {
				type: "event",
				events: ["Prigovor", "Faul", "Ruka"],
				comment: "${event}.",
			},
			if: { has: 2, name: "Drugi žuti karton" },
			comment:
				"Žuti karton dobio je sada ${name} (${teamName}) jer je počinio prekršaj:",
			key: "Yellow card",
			types: ["A", "B"],
		},
		{
			name: "Drugi žuti",
			followup: {
				type: "event",
				events: ["Prigovor", "Faul", "Ruka"],
				comment: "${event}.",
			},
			comment:
				"CRVENI KARTON! Drugi žuti karton dobio je sada ${name} (${teamName}) jer je počinio prekršaj:",
			key: "Red card",
			types: ["A", "B"],
		},
		{
			name: "Izravan crveni karton",
			followup: {
				type: "event",
				events: ["Prigovor", "Faul", "Ruka"],
				comment:
					"${event}. Ovo je bilo iznimno nepotrebno i neprimjereno. Ovo su sigurno minimalno dvije utakmice kazne.",
			},
			comment:
				"Izravan crveni karton dobio je sada ${name} (${teamName}) jer je počinio prekršaj:",
			key: "Red card",
			types: ["A", "B", "C"],
		},
		{
			name: "Stativa",
			comment:
				"Odličan pokušaj, ali nije ${name} (${teamName}) imao sreće. Udarac je završio na stativi.",
			key: "Post",
			types: ["A"],
		},
		{
			name: "Aktivan golman igrač",
			key: "Active goalkeeper",
			types: ["A"],
		},
		{
			name: "6 gol",
			increment: 1,
			comment:
				"Precizan sa šest metara bio je ${name} (${teamName}). Neobranjivo za protivničkog vratara.",
			key: "6m goal",
			isPenalty: true,
			types: ["A"],
			coordinate: { x: 6, y: 11 },
		},
		{
			name: "6 obrana",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali ${name} (${teamName}) je to obranio.",
			},
			comment: "Obrana! Sa šest metara ${name} (${teamName}) je pucao,",
			key: "6m on target",
			isPenalty: true,
			types: ["A"],
			coordinate: { x: 6, y: 11 },
		},
		{
			name: "6 stativa",
			// They probably want teamName here instead of tshirtNumber
			comment:
				"Nema pogotka, stativa. Sa šest metara neprecizan je bio ${name}. Nedostajalo je ovdje malo sreće za ${teamName}.",
			key: "6m post",
			isPenalty: true,
			types: ["A"],
			coordinate: { x: 6, y: 11 },
		},
		{
			name: "6 promašaj",
			comment:
				"Kakav promašaj! Sa šest metara neprecizan je bio ${name} (${teamName}). Udarac je završio pored gola.",
			key: "6m off target",
			isPenalty: true,
			types: ["A"],
			coordinate: { x: 6, y: 11 },
		},
		{
			name: "10 gol",
			increment: 1,
			// They probably want teamName here instead of tshirtNumber
			comment:
				"Gooooool. Odlično izveden deseterac za ${teamName}. Precizan je bio ${name}. Neobranjivo za protivničkog vratara.",
			key: "10m goal",
			types: ["A"],
			coordinate: { x: 10, y: 11 },
		},
		{
			name: "10 obrana",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali ${name} (${teamName}) je to obranio.",
			},
			comment: "Obrana! Sa deset metara ${name} (${teamName}) je pucao,",
			key: "10m on target",
			types: ["A"],
			coordinate: { x: 10, y: 11 },
		},
		{
			name: "10 stativa",
			// They probably want teamName here instead of tshirtNumber
			comment:
				"Nema pogotka, stativa. ${name} nije iskoristio deseterac. Nedostajalo je ovdje malo sreće za ${teamName}.",
			key: "10m post",
			types: ["A"],
			coordinate: { x: 10, y: 11 },
		},
		{
			name: "10 promašaj",
			comment:
				"Deseterac koji je uputio ${name} (${teamName}) je završio pored gola. Nema pogotka.",
			key: "10m off target",
			types: ["A"],
			coordinate: { x: 10, y: 11 },
		},
	],
}

export default {
	quick: [
		{
			name: "U okvir",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali siguran je na golu bio ${name} (${teamName}).",
			},
			comment:
				"Bio je ovo dobar pokušaj za ${teamName}. Udarac je uputio ${name},",
			key: "Shot on target",
			types: ["A"],
		},
		{
			name: "Stativa",
			comment:
				"Odličan pokušaj, ali nije ${name} (${teamName}) imao sreće. Udarac je završio na stativi.",
			key: "Post",
			types: ["A"],
		},
		{
			name: "Van gola",
			comment:
				"Pokušao je sada ${name} (${teamName}), ali udarac je bio neprecizan i završio je pored gola.",
			key: "Shot off target",
			types: ["A"],
		},
		{
			name: "Faul 20s",
			comment: "${name} je suspendiran na 20 sekundi",
			key: "Suspension",
			types: ["A", "B"],
		},
	],
	normal: [
		{
			name: "Gol",
			key: "Goal",
			types: ["A", "B", "C"],
			comment: "Gooooool. Pogodak je postigao ${name} (${teamName}).",
			followup: {
				type: "teamPlayers",
				allowIgnore: true,
				comment: "Asistirao je ${name}.",
			},
			increment: 1,
		},
		{
			name: "Autogol",
			followup: {
				type: "enemyPlayers",
				allowIgnore: true,
				comment:
					"Na to ga je potaknuo ${name} (${teamName}) iz protivničke ekipe.",
			},
			comment:
				"AUTOGOL! Nespretnom reakcijom ${name} (${teamName}) posprema loptu u vlastitu mrežu.",
			key: "Own goal",
			types: ["A", "B", "C"],
			increment: -1,
		},
		{
			name: "5 gol",
			key: "5m goal",
			types: ["A", "B"],
			comment:
				"Precizan s pet metara bio je ${name} (${teamName}). Neobranjivo za protivničkog vratara.",
			increment: 1,
			isPenalty: true,
			coordinate: { x: 4, y: 11 },
		},
		{
			name: "5 obrana",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali ${name} (${teamName}) je to obranio.",
			},
			comment: "Obrana! Sa pet metara ${name} (${teamName}) je pucao,",
			key: "5m on target",
			types: ["A"],
			isPenalty: true,
			coordinate: { x: 4, y: 11 },
		},
		{
			name: "5 stativa",
			comment:
				// They probably want teamName here instead of tshirtNumber
				"Nema pogotka, stativa. S pet metara neprecizan je bio ${name}. Nedostajalo je ovdje malo sreće za ${teamName}.",
			key: "5m post",
			types: ["A"],
			isPenalty: true,
			coordinate: { x: 4, y: 11 },
		},
		{
			name: "5 van gola",
			comment:
				"Kakav promašaj! S pet metara neprecizan je bio ${name} (${teamName}). Udarac je završio pored gola.",
			key: "5m off target",
			types: ["A"],
			isPenalty: true,
			coordinate: { x: 4, y: 11 },
		},
		{
			name: "Igrač više - gol",
			comment:
				// They probably want teamName here instead of tshirtNumber
				"Gooooool. Odlično izveden napad za ${teamName} s igračem više. Precizan je bio ${name}. Neobranjivo za protivničkog vratara.",
			increment: 1,
			key: "Goal +1",
			types: ["A", "B"],
		},
		{
			name: "Igrač više - obrana",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "${name} (${teamName}) je obranio gol.",
			},
			comment:
				"Obrana! Neuspješna akcija s igračem više za ${name} (${teamName}).",
			key: "Shot on target +1",
			types: ["A"],
		},
		{
			name: "Igrač više - stativa ",
			comment:
				"Nema pogotka, stativa. ${name} nije iskoristio priliku nakon ovog napada s igračem više. Nedostajalo je ovdje malo sreće za ${teamName}.",
			key: "Post +1",
			types: ["A"],
		},
		{
			name: "Igrač više - van gola",
			comment:
				"Udarac nakon napada s igračem više koji je uputio ${name} (${teamName}) završio je pored gola. Nema pogotka.",
			key: "Shot off target +1",
			types: ["A"],
		},
		{
			name: "Drugi žuti",
			comment:
				"Isključenje za ${name} (${teamName}). Prilika za protivnika za napad s igračem više.",
			key: "Red card",
			types: ["A", "B"],
		},
		{
			name: "Rasplivavanje",
			comment:
				// They probably want teamName here instead of tshirtNumber
				"Lopta za ${teamName}. ${name} je bio brži u rasplivavanju i osvojio je loptu za svoju ekipu.",
			// Add key here if backend needs statistics for this
			types: ["A", "B"],
		},
	],
}

import ConfigAthletics from "./allSportConfigs/athletics"
import ConfigFutsal from "./allSportConfigs/futsal"
import ConfigBasketball from "./allSportConfigs/basketball"
import ConfigBasketball3x3 from "./allSportConfigs/basketball3x3"
import ConfigHandball from "./allSportConfigs/handball"
import ConfigVolleyball from "./allSportConfigs/volleyball"
import ConfigVolleyballNaPijesku from "./allSportConfigs/beachVolleyball"
import ConfigFootball from "./allSportConfigs/football"
import ConfigWaterpolo from "./allSportConfigs/vaterpolo"
import ConfigBadminton from "./allSportConfigs/badminton"
import ConfigJudo from "./allSportConfigs/judo"
import ConfigKarate from "./allSportConfigs/karate"
import ConfigKickboxing from "./allSportConfigs/kickboxing"
import ConfigChess from "./allSportConfigs/chess"
import ConfigSquash from "./allSportConfigs/squash"
import ConfigStolniTenis from "./allSportConfigs/tableTennis"
import ConfigTaekwondo from "./allSportConfigs/taekwondo"
import ConfigTenis from "./allSportConfigs/tennis"
import ConfigSwimming from "./allSportConfigs/swimming"
import ConfigESports from "./allSportConfigs/esport"

export const getSportsConfig = (sport) => {
	switch (sport?.toLowerCase()) {
		case "athletics":
			return ConfigAthletics
		case "futsal":
			return ConfigFutsal
		case "basketball":
			return ConfigBasketball
		case "basketball3v3":
			return ConfigBasketball3x3
		case "handball":
			return ConfigHandball
		case "volleyball":
			return ConfigVolleyball
		case "beach volleyball":
			return ConfigVolleyballNaPijesku
		case "football":
			return ConfigFootball
		case "waterpolo":
			return ConfigWaterpolo
		case "badminton":
			return ConfigBadminton
		case "judo":
			return ConfigJudo
		case "karate":
			return ConfigKarate
		case "kickboxing":
			return ConfigKickboxing
		case "chess":
			return ConfigChess
		case "squash":
			return ConfigSquash
		case "table tenis":
		case "table tennis":
			return ConfigStolniTenis
		case "taekwondo":
			return ConfigTaekwondo
		case "tenis":
		case "tennis":
			return ConfigTenis
		case "swimming":
			return ConfigSwimming
		case "esport":
			return ConfigESports
		default:
			return {}
	}
}

export const getAllSports = () => {
	return [
		"Athletics",
		"Badminton",
		"Basketball",
		"Basketball3v3",
		"Beach Volleyball",
		"Chess",
		"Esport",
		"Football",
		"Futsal",
		"Handball",
		"Judo",
		"Karate",
		"Kickboxing",
		"Squash",
		"Swimming",
		"Table tenis",
		"Taekwondo",
		"Tennis",
		"Waterpolo",
		"Volleyball",
	]
}

export const getAllJerseys = () => {
	return [
		{
			name: "Bijeli dres, crni broj",
			jersey: "white",
			number: "black"
		},
		{
			name: "Sivi dres, bijeli broj",
			jersey: "grey",
			number: "white"
		},
		{
			name: "Crni dres, bijeli broj",
			jersey: "black",
			number: "white",
		},
		{
			name: "Bordo crveni dres, bijeli broj",
			jersey: "#5F021F", /* Bordaeux red*/
			number: "white"
		},
		{
			name: "Crveni dres, bijeli broj",
			jersey: "red",
			number: "white"
		},
		{
			name: "Tamno plavi dres, bijeli broj",
			jersey: "#004182", /* Dark blue */
			number: "white"
		},
		{
			name: "Svijetlo plavi dres, crni broj",
			jersey: "#008ae6", /* Light blue */
			number: "black"
		},
		{
			name: "Zeleni dres, bijeli broj",
			jersey: "green",
			number: "white"
		},
		{
			name: "Žuti dres, crni broj",
			jersey: "yellow",
			number: "black"
		},
		{
			name: "Narančasti dres, crni broj",
			jersey: "orange",
			number: "black"
		},
		{
			name: "Rozi dres, crni broj",
			jersey: "pink",
			number: "black"
		},
		{
			name: "Ljubičasti dres, bijeli broj",
			jersey: "purple",
			number: "white"
		}
	]
}

export const isSportSockets = (sport) => getSportsConfig(sport).hasSockets

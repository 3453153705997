import moment from "moment"
import { addArrayAndSort } from "../redux/actions/actions"
import { getSportsConfig } from "./constants/sportConfig"
import { toast } from "react-toastify"
import { insertInSortedArray } from "../redux/reducers/allReducers/events"

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

export const compareByProperty = (a, b, property) => {
	if (parseInt(a[property]) < parseInt(b[property])) {
		return -1
	} else if (parseInt(a[property]) > parseInt(b[property])) {
		return 1
	}
	return 0
}

export const getEventMessage = (
	selectedEvent,
	selectedPlayer,
	followupPlayer,
	followupEvent
) => {
	const message = (selectedEvent?.comment ?? selectedEvent?.name ?? "")
		.replaceAll(
			"${name}",
			selectedPlayer?.name
				? `${selectedPlayer?.name} ${selectedPlayer?.surname}`
				: "-"
		)
		.replaceAll("${teamName}", selectedPlayer?.team ?? "-")

	if (selectedEvent.followup) {
		if (selectedEvent.followup.type == "event" && followupEvent) {
			const followupMessage = (
				selectedEvent?.followup?.comment ?? ""
			).replaceAll("${event}", followupEvent ?? "-")

			return message + " " + followupMessage
		} else if (followupPlayer?.name) {
			const followupMessage = (selectedEvent?.followup?.comment ?? "")
				.replaceAll(
					"${name}",
					followupPlayer?.name
						? `${followupPlayer?.name} ${followupPlayer?.surname}`
						: "-"
				)
				.replaceAll("${teamName}", followupPlayer?.team ?? "-")

			return message + " " + followupMessage
		}
	}

	return message
}

export const getCurrentTime = (
	timeState = JSON.parse(localStorage.getItem("time"))
) => {
	if (!timeState || !Object.entries(timeState).length)
		return { halftime: "00:00", fulltime: "00:00" }
	const { timeElapsed, startTime, fullTimeElapsed } = {
		timeElapsed: 0,
		startTime: 0,
		fullTimeElapsed: 0,
		...timeState,
	}

	if (!startTime)
		return {
			halftime: moment(timeElapsed).format("mm:ss"),
			fulltime: moment(fullTimeElapsed + timeElapsed).format("mm:ss"),
		}

	const moreTime = timeElapsed + moment.now() - startTime
	const maxTime = moment(0).minutes(
		timeState.overtime
			? getSportsConfig(localStorage.getItem("sport")).overtimeMinutes
			: localStorage.getItem("halftime")
	)

	const newTime = moreTime < maxTime ? moreTime : maxTime

	return {
		halftime: moment(newTime).format("mm:ss"),
		fulltime: moment(fullTimeElapsed + newTime).format("mm:ss"),
	}
}

export const getHalftimeCharacter = () => {
	const { numOfHalftimes } = getSportsConfig(localStorage.getItem("sport"))
	if (numOfHalftimes === 2) {
		return "H"
	} else if (numOfHalftimes === 4) {
		return "Q"
	} else if (numOfHalftimes) {
		return "Dio"
	} else {
		return
	}
}

export const getPenaltyEvents = (sport = localStorage.getItem("sport")) => {
	const { events } = getSportsConfig(sport)
	return [...events.quick, ...events.normal]
		.filter((event) => event.isPenalty)
		.map((event) => {
			return {
				...event,
				comment: event.comment.replaceAll(/na ([A-z])*/g, "na penalu"),
				key:
					"Penalty" +
					event.key.substr(parseInt(event.key).toString().length + 1),
				name:
					"Penal" +
					event.name.substr(parseInt(event.name).toString().length),
			}
		})
}

export const filterEventsByDelegateType = (sport) => {
	const { events: originalEvents } = getSportsConfig(
		sport ?? localStorage.getItem("sport")
	)
	if (!originalEvents || !originalEvents.normal.length) {
		return {}
	}

	const delegateType = localStorage.getItem("delegateType") ?? "A"
	const filteredEvents = [
		...originalEvents.quick,
		...originalEvents.normal,
	].filter((event) => event.types.includes(delegateType))

	return {
		quick: filteredEvents.slice(0, Math.min(4, filteredEvents.length)),
		normal: filteredEvents.slice(4, filteredEvents.length),
	}
}

export const onPostGetEvents = (
	body,
	dispatch,
	{ events },
	hideToast = false,
	homeTeam,
	awayTeam
) => {
	homeTeam ??= JSON.parse(localStorage.getItem("homeTeam") ?? "{}")
	awayTeam ??= JSON.parse(localStorage.getItem("awayTeam") ?? "{}")
	const eventsInArray = getAllEvents(body.sport)

	if (!events || events.length === 0) return

	const newEvents = []
	for (let x = 0; x < events.length; x++) {
		const element = events[x]
		let event = eventsInArray.find((ev) => ev.key === element.type)
		if (!event) {
			const penaltyEvents = eventsInArray.filter((ev) => ev.isPenalty)

			event = penaltyEvents.find((ev) => {
				return (
					"Penalty" +
						ev.key.substr(parseInt(ev.key).toString().length + 1) ==
					element.type?.replaceAll("  ", " ")
				)
			})
			if (event) {
				event = {
					...event,
					name:
						"Penal " +
						event.name
							.replaceAll("  ", " ")
							.substr(parseInt(event.name).toString().length + 1),
				}
			} else {
				event = { name: element.type }
			}
		}

		const player = !element.player
			? null
			: [...homeTeam.players, ...awayTeam.players].find(
					(pl) => pl.playerId === element.player
			  )
		insertInSortedArray(newEvents, {
			coordinates: element.coordinates,
			team: element.isHomeTeam ? homeTeam.name : awayTeam.name,
			player2Id: element.player2,
			playerId: element.player,
			comment: element.comment,
			quarter: element.quarter,
			event: event.name,
			id: element._id,
			time: element.time,
			order: element.order,
			playerName: player ? player?.name + " " + player?.surname : null,
			score: !event.increment
				? null
				: event.increment > 0
				? {
						team: element.isHomeTeam ? "homeTeam" : "awayTeam",
						value: event.increment,
				  }
				: {
						team: !element.isHomeTeam ? "homeTeam" : "awayTeam",
						value: -event.increment,
				  },
		})
	}
	if (dispatch) dispatch(addArrayAndSort(newEvents))
	if (!hideToast) toast.success("Uspješno hvaćanje svih evenata")
	return newEvents
}

export const saveSocketlessMatchLocally = (sport, game) => {
	const { isIndividualSport, layout, contestantsName } = getSportsConfig(sport)
	localStorage.setItem("sport", sport)

	if (layout === "discipline") {
		localStorage.setItem("discipline", game.discipline)
		const contestants = game[contestantsName]
		localStorage.setItem(
			"contestants",
			JSON.stringify(
				contestants.map((playerOrTeam) => {
					return {
						playerId: playerOrTeam?._id,
						name: playerOrTeam?.name ?? "",
						surname: playerOrTeam?.surname ?? "",
						team: playerOrTeam?.team ?? "",
					}
				})
			)
		)
		if (game.result && game.result.length > 0) {
			localStorage.setItem(
				"old-result",
				JSON.stringify(
					game.result.map((playerOrTeam) => {
						return {
							id: playerOrTeam?.swimmer,
							result: playerOrTeam?.result,
							comment: playerOrTeam?.comment,
						}
					})
				)
			)
		}
	} else {
		const { homeTeam, awayTeam } = isIndividualSport
			? {
					homeTeam: {
						name: game.homePlayer.name,
						surname: game.homePlayer.surname,
						playerId: game.homePlayer._id,
					},
					awayTeam: {
						name: game.awayPlayer.name,
						surname: game.awayPlayer.surname,
						playerId: game.awayPlayer._id,
					},
			  }
			: game

		localStorage.setItem("homeTeam", JSON.stringify(homeTeam))
		localStorage.setItem("awayTeam", JSON.stringify(awayTeam))
	}
	localStorage.setItem(
		"gameDetails",
		JSON.stringify({
			location: location ?? "-",
			time: game.date ? moment(game.date).format("DD/MM/yyyy - HH:mm") : "-",
			gameId: game._id,
		})
	)
}

export const getAllEvents = (sport) => {
	const events = filterEventsByDelegateType(sport)
	return [
		{ name: "Ekipni Komentar", key: "team comment", noPlayer: true },
		{ name: "Komentar", key: "comment", noPlayer: true, noTeam: true },
		{
			name: "Zamjena",
			key: "switch",
			followup: {
				type: "teamPlayers",
				comment: "Na njegovo mjesto ide (${teamName}) ${name}.",
			},
			comment: "(${teamName}) ${name} ide na klupu.",
		},
		...events.quick,
		...events.normal,
		...getPenaltyEvents(sport),
	]
}

export default {
	quick: [
		{
			name: "Prekršaj",
			followup: {
				type: "event",
				events: ["Faul", "Ruka"],
				comment: "${event}.",
			},
			comment:
				"Sudac se oglasio jer je ${name} (${teamName}) počinio prekršaj:",
			key: "Foul",
			types: ["A", "B"],
		},
		{
			name: "U okvir",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali siguran je na golu bio ${name} (${teamName}).",
			},
			// They probably want teamName here instead of tshirtNumber
			comment:
				"Bio je ovo dobar pokušaj za ${teamName}. Udarac je uputio ${name},",
			key: "Shot on target",
			types: ["A"],
		},
		{
			name: "Van gola",
			comment:
				"Pokušao je sada ${name} (${teamName}), ali udarac je bio neprecizan i završio je pored gola.",
			key: "Shot off target",
			types: ["A"],
		},
		{
			name: "Blok",
			// They probably want teamName here instead of tshirtNumber
			comment:
				"Jedan pokušaj za ${teamName}, ali udarac koji je uputio ${name} je završio u bloku.",
			key: "Blocked shot",
			types: ["A"],
		},
	],
	normal: [
		{
			name: "Gol",
			followup: {
				type: "teamPlayers",
				allowIgnore: true,
				comment: "Asistirao je ${name}.",
			},
			increment: 1,
			comment: "Gooooool. Pogodak je postigao ${name} (${teamName}).",
			key: "Goal",
			types: ["A", "B", "C"],
		},
		{
			name: "Autogol",
			followup: {
				type: "enemyPlayers",
				allowIgnore: true,
				comment:
					"Na to ga je potaknuo ${name} (${teamName}) iz protivničke ekipe.",
			},
			increment: -1,
			comment:
				"AUTOGOL! Nespretnom reakcijom ${name} (${teamName}) posprema loptu u vlastitu mrežu.",
			key: "Own goal",
			types: ["A", "B", "C"],
		},
		{
			name: "Žuti karton",
			followup: {
				type: "event",
				events: ["Prigovor", "Faul", "Ruka"],
				comment: "${event}.",
			},
			if: { has: 2, name: "Drugi žuti karton" },
			comment:
				"Žuti karton dobio je sada ${name} (${teamName}) jer je počinio prekršaj:",
			key: "Yellow card",
			types: ["A", "B"],
		},
		{
			name: "Drugi žuti",
			followup: {
				type: "event",
				events: ["Prigovor", "Faul", "Ruka"],
				comment: "${event}.",
			},
			comment:
				"CRVENI KARTON! Drugi žuti karton dobio je sada ${name} (${teamName}) jer je počinio prekršaj:",
			key: "Red card",
			types: ["A", "B"],
		},
		{
			name: "Izravan crveni karton",
			followup: {
				type: "event",
				events: ["Prigovor", "Faul", "Ruka"],
				comment:
					"${event}. Ovo je bilo iznimno nepotrebno i neprimjereno. Ovo su sigurno minimalno dvije utakmice kazne.",
			},
			comment:
				"Izravan crveni karton dobio je sada ${name} (${teamName}) jer je počinio prekršaj:",
			key: "Red card",
			types: ["A", "B"],
		},
		{
			name: "Stativa",
			comment:
				"Odličan pokušaj, ali nije ${name} (${teamName}) imao sreće. Udarac je završio na stativi.",
			key: "Post",
			types: ["A"],
		},
		{
			name: "11 gol",
			comment:
				"Precizan s jedanaest metara bio je ${name} (${teamName}). Neobranjivo za protivničkog vratara.",
			increment: 1,
			isPenalty: true,
			key: "11m goal",
			types: ["A", "B"],
			coordinate: { x: 4, y: 11 },
		},
		{
			name: "11 obrana",
			key: "11m on target",
			types: ["A"],
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali ${name} (${teamName}) je to obranio.",
			},
			comment: "Obrana! Sa jedanaest metara ${name} (${teamName}) je pucao,",
			isPenalty: true,
			coordinate: { x: 4, y: 11 },
		},
		{
			name: "11 stativa",
			key: "11m post",
			types: ["A"],
			comment:
				// They probably want teamName here instead of tshirtNumber
				"Nema pogotka, stativa. S jedanaest metara neprecizan je bio ${name}. Nedostajalo je ovdje malo sreće za ${teamName}.",
			isPenalty: true,
			coordinate: { x: 4, y: 11 },
		},
		{
			name: "11 promašaj",
			key: "11m off target",
			types: ["A"],
			comment:
				"Kakav promašaj! S jedanaest metara neprecizan je bio ${name} (${teamName}). Udarac je završio pored gola.",
			isPenalty: true,
			coordinate: { x: 4, y: 11 },
		},
		{
			name: "Slobodni udarac - gol",
			increment: 1,
			comment:
				// They probably want teamName here instead of tshirtNumber
				"Gooooool. Odlično izveden deseterac za ${teamName}. Precizan je bio ${name}. Neobranjivo za protivničkog vratara.",
			key: "Free kick goal",
			types: ["A", "B"],
		},
		{
			name: "Slobodni udarac - obrana",
			followup: {
				type: "enemyPlayers",
				filter: ["GK", "Bench-GK"],
				comment: "ali ${name} (${teamName}) je obranio gol.",
			},
			comment: "Obrana! ${name} (${teamName}) je pucao slobodni udarac,",
			key: "Free kick on target",
			types: ["A"],
		},
		{
			name: "Slobodni udarac - stativa",
			comment:
				// They probably want teamName here instead of tshirtNumber
				"Nema pogotka, stativa. ${name} nije iskoristio slobodni udarac. Nedostajalo je ovdje malo sreće za ${teamName}.",
			key: "Free kick post",
			types: ["A"],
		},
		{
			name: "Slobodni udarac - van okvira",
			comment:
				"Slobodni udarac koji je uputio ${name} (${teamName}) završio je pored gola. Nema pogotka.",
			key: "Free kick off target",
			types: ["A"],
		},
		{
			name: "Slobodni udarac - blok u živi zid",
			comment:
				"Slobodni udarac koji je uputio ${name} (${teamName}) završio je u živom zidu. Nema pogotka.",
			key: "Free kick wall",
			types: ["A"],
		},
		{
			name: "Korner",
			// They probably want teamName here instead of tshirtNumber
			comment: "Bit će sada korner za ${teamName}.",
			key: "Corner",
			types: ["A", "B"],
		},
	],
}

// Missing: Suspension, Offside

export default {
	quick: [
		{
			name: "Smash",
			increment: 1,
			comment: "Pogodak. Odličan udarac za ${name} (${teamName}).",
			key: "Smash",
			types: ["A", "B"],
		},
		{
			name: "As",
			increment: 1,
			comment:
				"Kakva serva. Fantastičan i neobranjiv udarac za ${name} (${teamName}).",
			key: "Ace",
			types: ["A"],
		},
		{
			name: "Promašaj",
			increment: -1,
			comment:
				"Greška sada za ${name} (${teamName}). Neprecizan pokušaj, poen za protivnika.",
			key: "Error",
			types: ["A", "B"],
		},
		{
			name: "Blokada",
			increment: 1,
			comment:
				"Blokadaaa. Fantastična obrambena reakcija za ${name} (${teamName}).",
			key: "Block",
			types: ["A"],
		},
	],
	normal: [
		{
			name: "Žuti karton",
			comment:
				"Opomenu, odnosno žuti karton je sada zaradio ${name} (${teamName}).",
			key: "Yellow card",
			types: ["A"],
		},
		{
			name: "Drugi žuti",
			comment: "Crveni karton je sada dobio ${name} (${teamName}).",
			key: "Red card",
			types: ["A"],
		},
		{
			name: "Žuti i crveni karton skupa",
			comment:
				"Isključenje, odnosno žuti i crveni karton zajedno dobio je sada ${name} (${teamName}).",
			key: "Red and Yellow card",
			types: ["A"],
		},
		{
			name: "Žuti i crveni karton odvojeno",
			comment:
				"Odstranjen je sada ${name} (${teamName}), nakon što je dobio žuti i crveni karton odvojeno.",
			key: "Red and Yellow card",
			types: ["A"],
		},
		{
			name: "Zeleni karton",
			comment: "Zeleni karton je sada dobio ${name} (${teamName}).",
			key: "Green card",
			types: ["A"],
		},
	],
}

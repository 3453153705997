import { EventsFootball } from "../../events/index"

export default {
	layout: "court",
	events: EventsFootball,
	field: "court-football",
	numOfHalftimes: 2,
	overtimeMinutes: 15,

	penalties: true,
	playerNumbers: true,
	hasBench: true,
	hasCaptain: true,
	hasMvp: true,
	hasSockets: true,
	hasPauses: true,
	twoJerseyColors: true,


	formations: {
		"4-4-2": [
			{ name: "GK", coordinate: { x: 0, y: 11 } },
			{ name: "RB", coordinate: { x: 8, y: 3 } },
			{ name: "DCR", coordinate: { x: 7, y: 8 } },
			{ name: "DCL", coordinate: { x: 7, y: 14 } },
			{ name: "LB", coordinate: { x: 8, y: 19 } },
			{ name: "RM", coordinate: { x: 11, y: 4 } },
			{ name: "CMR", coordinate: { x: 11, y: 8 } },
			{ name: "CML", coordinate: { x: 11, y: 14 } },
			{ name: "LM", coordinate: { x: 11, y: 18 } },
			{ name: "STR", coordinate: { x: 15, y: 13 } },
			{ name: "STL", coordinate: { x: 15, y: 9 } },
		],
		"4-3-3": [
			{ name: "GK", coordinate: { x: 0, y: 11 } },
			{ name: "RB", coordinate: { x: 8, y: 3 } },
			{ name: "DCR", coordinate: { x: 7, y: 8 } },
			{ name: "DCL", coordinate: { x: 7, y: 14 } },
			{ name: "LB", coordinate: { x: 8, y: 19 } },
			{ name: "DM", coordinate: { x: 11, y: 11 } },
			{ name: "CMR", coordinate: { x: 11, y: 8 } },
			{ name: "CML", coordinate: { x: 11, y: 14 } },
			{ name: "LW", coordinate: { x: 15, y: 5 } },
			{ name: "RW", coordinate: { x: 15, y: 17 } },
			{ name: "ST", coordinate: { x: 17, y: 11 } },
		],
		"3-4-3": [
			{ name: "GK", coordinate: { x: 0, y: 11 } },
			// 3
			{ name: "RCB", coordinate: { x: 7, y: 8 } },
			{ name: "CB", coordinate: { x: 7, y: 11 } },
			{ name: "LCB", coordinate: { x: 7, y: 14 } },
			// 4
			{ name: "RM", coordinate: { x: 11, y: 4 } },
			{ name: "CMR", coordinate: { x: 11, y: 8 } },
			{ name: "CML", coordinate: { x: 11, y: 14 } },
			{ name: "LM", coordinate: { x: 11, y: 18 } },
			// 3
			{ name: "LW", coordinate: { x: 15, y: 5 } },
			{ name: "RW", coordinate: { x: 15, y: 17 } },
			{ name: "ST", coordinate: { x: 17, y: 11 } },
		],
	},

	teamComments: [
		"${name} u potpunosti dominira ovom utakmicom. Odlična utakmica za njih.",
		"Mirna utakmica za sada, uz malu inicijativu koju pokazuje ${name}.",
		"${name} drži posjed lopte te pametno umara svog protivnika.",
	],
}

import { EventsHandball } from "../../events/index"

export default {
	layout: "court",
	events: EventsHandball,
	field: "court-handball",
	overtimeMinutes: 5,
	numOfHalftimes: 2,

	penalties: true,
	playerNumbers: true,
	hasBench: true,
	hasCaptain: true,
	hasMvp: true,
	hasSockets: true,
	hasPauses: true,
	teamPausesAllowed: 3,
	twoJerseyColors: true,


	formations: {
		default: [
			{ name: "GK", coordinate: { x: 1, y: 11 } },
			{ name: "SV", coordinate: { x: 15, y: 11 } },
			{ name: "LV", coordinate: { x: 13, y: 6 } },
			{ name: "DV", coordinate: { x: 13, y: 16 } },
			{ name: "LK", coordinate: { x: 8, y: 2 } },
			{ name: "DK", coordinate: { x: 8, y: 20 } },
			{ name: "PIV", coordinate: { x: 9, y: 11 } },
		],
	},
	teamComments: [
		"Minuta odmora za ${name}. Sada je na izborniku da pripremi svoju ekipu najbolje što može za nastavak.",
		"Pokušat će sada ${name} s golmanom igračem.",
		"Suci su podigli ruku. Nema još puno vremena da ${name} završi svoj napad.",
		"Pasivni napad za ${name}. Suci su im oduzeli loptu i dodijelili protivniku.",
	],
}

/* eslint-disable quotes */
import { ALL_RESET, FORCE_RERENDER, FORCE_ISLOADING } from "../../actions/actions"

// **  Initial State
const initialState = { num: 0, isLoading: true }

// ** Reducer
export default (state = initialState, action) => {
	switch (action.type) {
		case FORCE_ISLOADING:
			return { ...state, isLoading: action.value }
		case FORCE_RERENDER:
			return { ...state, num: state.num + 1 }
		case ALL_RESET:
			return initialState
		default:
			return state
	}
}
